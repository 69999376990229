import React, { useState, useEffect } from 'react';
import baseUrl from '../config/baseUrl';
import { Helmet } from 'react-helmet';
import TranslateIcon from '@mui/icons-material/Translate';
import './googleTranslate.css';
import { useTheme } from '@mui/material';
import {
    Alert,
    Container,
    Grid,
    Box,
    useMediaQuery,
    Button,
    Typography,
    IconButton,
    ListItemIcon,
    Snackbar,
    Stack
} from '@mui/material';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import { Masonry } from '@mui/lab';
import heroImage from '../assets/images/Frame 1984077470.png';
import web3 from '../assets/images/web3.png';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import styled from '@emotion/styled';
// import TwitterLogo from '../assets/images/TwitterLogo';
// import TelegramLogo from '../assets/images/TelegramLogo';
import trendLogo from '../assets/images/logo.png';
import twitterLogo from '../assets/images/social media logos.png';
import telegramLogo from '../assets/images/logos_telegram.png';
import shineSquares from '../assets/images/shine_squers.png';
import image1 from '../assets/images/1.png';
import image2 from '../assets/images/2.png';
import image3 from '../assets/images/3.png';
import image4 from '../assets/images/4.png';
import image5 from '../assets/images/5.png';
import image6 from '../assets/images/6.png';
import image7 from '../assets/images/7.png';
import image8 from '../assets/images/8.png';
import image9 from '../assets/images/9.png';
import s1 from '../assets/images/s1.png';
import s2 from '../assets/images/s2.png';
import s3 from '../assets/images/s3.png';
import s4 from '../assets/images/s4.png';
// import productSec1 from '../assets/images/productSec1.png';
import productSec2 from '../assets/images/productSec2.png';
import productSec3 from '../assets/images/productSec3.png';
import productSec4 from '../assets/images/productSec4.png';
import viewerBackground from '../assets/images/viewerBackground.png';
import advertiserBackground from '../assets/images/advertiserBackground.png';
import viewer from '../assets/images/viewer.png';
import docs from '../assets/images/docs.png';
import bundle from '../assets/images/bundle.png';
import advertiser from '../assets/images/advertiser.png';
import connect from '../assets/images/connect.png';
import { FormatColorResetOutlined } from '@mui/icons-material';

const anotherButtons = [
    'gaming',
    'nft',
    'metaverse',
    'defi',
    'crypto',
    'web3',
    'dex'
];

const StyledMenu = styled(List)`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8); // Adjust opacity as needed
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateX(-100%); // Initially hidden off-screen
    transition: transform 0.3s ease-in-out;
    z-index: 1; // Ensure menu is above other content

    &.open {
        transform: translateX(0); // Slide in when open
    }
`;

const LandingPage = () => {
    const localStorageWalletAddress = localStorage.getItem('walletAddress');
    const theme = useTheme();
    // buttons
    const [buttons, setButtons] = useState([
        'Viewers',
        'Advertisers',
        // 'Advertising Bundles',
        // 'Docs',
        'Connect'
    ]);
    const xsMatched = useMediaQuery(theme.breakpoints.down('sx'));
    const smMatched = useMediaQuery(theme.breakpoints.down('sm'));
    const mdMatched = useMediaQuery(theme.breakpoints.down('md'));

    const [showGoogleTranslate, setShowGoogleTranslate] = useState(false);
    const [showMobGoogleTranslate, setShowMobGoogleTranslate] = useState(false);
    // for metaMask
    const [isConnecting, setIsConnecting] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [account, setAccount] = useState(null);

    // for snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const [isOpen, setIsOpen] = useState(false);
    const [activeButton, setActiveButton] = useState(null);
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const images = [
        { src: image1 },
        { src: image2 },
        { src: image3 },
        { src: image4 },
        { src: image5 },
        { src: image6 },
        { src: image7 }
        // { src: image8 }

        //         width: '450px', height: '278px'
        // width: '230px', height: '230px'
        // width: '230px', height: '450px'
        // width: '350px', height: '350px'
        // width: '230px', height: '450px'
        // width: '450px', height: '450px'
        // width: '230px', height: '230px'
        // width: '350px', height: '350px'
        // ... add more image objects
    ];

    useEffect(() => {
        if (isConnected) {
            // console.log('isConnected', isConnected);
            setSnackbarMessage('Connected to MetaMask');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);

            setButtons([
                'Viewers',
                'Advertisers',
                // 'Advertising Bundles',
                // 'Docs',
                'Disconnect'
            ]);
            setActiveButton('Disconnect');

            // const storeWalletAddress = addViewerWalletAddress(account);
            // if (storeWalletAddress) {
            //     // navigate('/dashboard');
            // }
        }
    }, [isConnected]);

    const handleConnect = async () => {
        // console.log('handleConnect');
        setIsConnecting(true);
        await connect();
    };

    const connect = async () => {
        // console.log('connect');
        if (localStorage.getItem('walletAddress')) {
            setIsConnected(true);

            // console.log('connected via localstorage address');
        } else {
            onLoadConnect();
        }
    };

    const onLoadConnect = async () => {
        // console.log('onLoadconnect');
        if (!window.ethereum) {
            setSnackbarMessage('Please install metamask');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);

            return;
        }
        if (window.ethereum) {
            try {
                const selectedAccount = await window.ethereum.request({
                    method: 'eth_requestAccounts'
                });
                setAccount(selectedAccount[0]);

                localStorage.setItem('WalletAddress', selectedAccount[0]);
                setIsConnected(true);
                // navigate('/dashboard');
            } catch (error) {
                // console.error('Connection error:', error);
                setSnackbarMessage('Connection error');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            } finally {
                setIsConnecting(false);
            }
        } else {
            // console.error('MetaMask not installed');
            setSnackbarMessage('MetaMask not installed');
            setSnackbarSeverity('warning');
            setSnackbarOpen(true);
        }
    };

    if (window.ethereum && window.ethereum.on) {
        window.ethereum.on('accountsChanged', newAccounts => {
            window.ethereum.on('accountsChanged', newAccounts => {
                setAccount(newAccounts[0]);
                setIsConnected(newAccounts?.length > 0);
                localStorage.setItem('WalletAddress', newAccounts[0]);
                // console.log('setting wallet address to local storage');
            });
        });
    }

    // const addViewerWalletAddress = async account => {
    //     if (account) {
    //         try {
    //             const res = await fetch(
    //                 `${baseUrl}/users/registerViewer/${account}`,
    //                 {
    //                     method: 'POST',
    //                     Headers: {
    //                         'Content-Type': 'application/json'
    //                     }
    //                 }
    //             );

    //             const data = await res?.json();

    //             if (!res.ok) {
    //                 console.log(data?.description);
    //                 return;
    //             }
    //             console.log('users/registerViewer', data[0]?.id);
    //             data[0]?.id && localStorage.setItem('userId', data[0]?.id);
    //             setUserId(data[0]?.id);

    //             return true;
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     }
    // };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const onClose = () => {
        setIsOpen(!isOpen);
    };

    const handleClick = buttonLabel => {
        setActiveButton(buttonLabel);

        if (buttonLabel === 'Viewers') {
            // let url = `http://208.76.222.129:8001`;
            let url = `https://viewer.trendads.ai`;
            window.open(url, '_blank');
        } else if (buttonLabel === 'Advertisers') {
            // let url = `http://208.76.222.129:8002`;
            let url = `https://advertiser.trendads.ai`;
            window.open(url, '_blank');
        } else if (buttonLabel === 'Connect') {
            handleConnect();
        } else if (buttonLabel === 'Disconnect') {
            localStorage.removeItem('walletAddress');
            setActiveButton('');
            setIsConnected(false);
            setAccount(null);
            setButtons([
                'Viewers',
                'Advertisers',
                // 'Advertising Bundles',
                // 'Docs',
                'Connect'
            ]);
            setSnackbarMessage('Wallet disconnected');
            setSnackbarSeverity('warning');
            setSnackbarOpen(true);
        } else {
        }

        if (buttonLabel === 'connectAsViewer') {
            // let url = `http://208.76.222.129:8001`;
            let url = `https://viewer.trendads.ai`;
            window.open(url, '_blank');
        }
        if (buttonLabel === 'connectAsAdvertiser') {
            // let url = `http://208.76.222.129:8002`;
            let url = `https://advertiser.trendads.ai`;
            window.open(url, '_blank');
        }

        if (buttonLabel === 'langButton') {
            setShowGoogleTranslate(!showGoogleTranslate);
        }

        if (buttonLabel === 'mobLangButton') {
            setShowMobGoogleTranslate(!showMobGoogleTranslate);
        }

        // console.log(`Button "${buttonLabel}" clicked!`);
    };

    const ImageIconButton = ({ imgToDisplay }) => {
        return (
            <img
                src={imgToDisplay}
                alt="Click Here"
                style={{ width: 32, height: 32 }}
            />
        );
    };

    return (
        <>
            <Helmet>
                <script type="text/javascript">
                    {`function googleTranslateElementInit() {
                        new google.translate.TranslateElement(
                            { pageLanguage: 'en' },
                            'google_translate_element'
                        );
                    }`}
                </script>
                <script
                    type="text/javascript"
                    src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
                ></script>
            </Helmet>

            {!smMatched && (
                <Container
                    maxWidth="lg"
                    style={{
                        height: '100%',
                        position: 'relative',
                        padding: 0,
                        // border: '2px dashed yellow',
                        paddingTop: '40px',
                        marginBottom: '40px'
                    }}
                >
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginRight: '40px'
                        }}
                    >
                        {/* box for language button and google translate */}
                        <Box
                            style={{
                                position: 'relative'
                            }}
                        >
                            <Button
                                sx={{
                                    zIndex: '10',
                                    fontSize:
                                        activeButton === 'langButton' ? 14 : 14,
                                    fontWeight:
                                        activeButton === 'langButton'
                                            ? 600
                                            : 500,
                                    fontFamily: 'Inter',
                                    color:
                                        activeButton === 'langButton'
                                            ? 'white'
                                            : '#D9D9D9', // Change color based on active state
                                    background:
                                        activeButton === 'langButton'
                                            ? 'linear-gradient(to right, #269D7E 100%, #24CD99 100%)' // Active button gradient
                                            : 'linear-gradient(101.76deg, rgba(255, 255, 255, 0.1) 16.55%, rgba(131, 131, 131, 0.1) 54.19%, rgba(203, 188, 188, 0.1) 94.97%)', // Default gradient
                                    borderRadius: '99px',
                                    padding: '10px 20px',
                                    margin: '5px',
                                    border: '1px solid #505050',
                                    textTransform: 'none'
                                }}
                                onClick={() => handleClick('langButton')}
                            >
                                <TranslateIcon />
                            </Button>
                            {/* ========================================= */}
                            {/* Google Translate element */}
                            <Box
                                style={{
                                    display: showGoogleTranslate
                                        ? 'block'
                                        : 'none',
                                    zIndex: '30',
                                    position: 'absolute',
                                    left: '50%',
                                    top: '50px',
                                    transform: 'translate(-50%)'
                                }}
                            >
                                <div
                                    style={{
                                        width: '100%',
                                        backgroundColor: '#269D7E',
                                        padding: '0px 8px',

                                        textAlign: 'center',
                                        borderRadius: '8px'
                                    }}
                                    id="google_translate_element"
                                ></div>
                            </Box>
                        </Box>

                        {buttons.map(buttonLabel => (
                            <Button
                                key={buttonLabel}
                                sx={{
                                    zIndex: '10',
                                    fontSize:
                                        activeButton === buttonLabel ? 14 : 14,
                                    fontWeight:
                                        activeButton === buttonLabel
                                            ? 600
                                            : 500,
                                    fontFamily: 'Inter',
                                    color:
                                        activeButton === buttonLabel
                                            ? 'white'
                                            : '#D9D9D9', // Change color based on active state
                                    background:
                                        activeButton === buttonLabel
                                            ? 'linear-gradient(to right, #269D7E 100%, #24CD99 100%)' // Active button gradient
                                            : 'linear-gradient(101.76deg, rgba(255, 255, 255, 0.1) 16.55%, rgba(131, 131, 131, 0.1) 54.19%, rgba(203, 188, 188, 0.1) 94.97%)', // Default gradient
                                    borderRadius: '99px',
                                    padding: '10px 20px',
                                    margin: '5px',
                                    border: '1px solid #505050',
                                    textTransform: 'none'
                                }}
                                onClick={() => handleClick(buttonLabel)}
                            >
                                {buttonLabel}
                            </Button>
                        ))}
                    </Box>
                    <Box
                        style={{
                            maxwWdth: '100%',
                            paddingLeft: '40px'

                            // border: '2px solid red'
                        }}
                    >
                        <img
                            src={trendLogo}
                            alt="compony logo"
                            style={{
                                position: 'absolute',
                                width: '20rem',
                                top: '4rem'
                            }}
                        />

                        <Box
                            sx={{
                                width: '628px',
                                height: '206px',

                                gap: '7px',
                                opacity: '0px'
                            }}
                        >
                            <Typography
                                sx={{
                                    mt: 5,
                                    fontFamily: 'Inter',
                                    fontSize: '36px',
                                    fontWeight: 700,
                                    lineHeight: '70px',
                                    letterSpacing: '0.06em',
                                    textAlign: 'left',
                                    color: '#D9D9D9'
                                }}
                            >
                                Transforming the <br /> digital advertising
                                landscape <br />
                                with{' '}
                                <span style={{ color: '#24CD99' }}>
                                    web3
                                </span>{' '}
                                technology
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Inter',
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    lineHeight: '29.05px',
                                    letterSpacing: '0.18em',
                                    textAlign: 'left',

                                    color: '#929292'
                                }}
                            >
                                The Future of Marketing is Here !
                            </Typography>
                        </Box>

                        <img
                            src={heroImage}
                            alt="image Web3"
                            style={{
                                zIndiex: '-10',
                                width: '100%',
                                height: 'auto',
                                position: 'absolute',
                                top: '50px',
                                left: '0px'
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            position: 'relative',
                            mt: 21,
                            ml: 15.5
                        }}
                    >
                        <Button
                            sx={{
                                zIndex: '10',
                                fontSize:
                                    activeButton === 'start advertising'
                                        ? 14
                                        : 14,
                                fontWeight:
                                    activeButton === 'start advertising'
                                        ? 600
                                        : 500,
                                fontFamily: 'Inter',
                                color:
                                    activeButton === 'start advertising'
                                        ? 'white'
                                        : '#D9D9D9', // Change color based on active state
                                background:
                                    activeButton === 'start advertising'
                                        ? 'linear-gradient(to right, #269D7E 100%, #24CD99 100%)' // Active button gradient
                                        : 'linear-gradient(101.76deg, rgba(255, 255, 255, 0.1) 16.55%, rgba(131, 131, 131, 0.1) 54.19%, rgba(203, 188, 188, 0.1) 94.97%)', // Default gradient
                                borderRadius: '17px',
                                padding: '10px 35px',

                                border: '1px solid #505050',
                                textTransform: 'uppercase'
                            }}
                            onClick={() => handleClick('start advertising')}
                        >
                            Start Advertising
                        </Button>
                    </Box>
                    <Box
                        style={{
                            position: 'relative',
                            width: '85%',
                            margin: '-70px auto',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-end'
                        }}
                    >
                        <Box>
                            {/* <Button startIcon=<TwitterLogo />>Twitter</Button> */}
                            <IconButton
                                onClick={() => {
                                    window.open(
                                        'https://twitter.com/TTAvatars',
                                        '_blank'
                                    );
                                }}
                            >
                                <ImageIconButton imgToDisplay={twitterLogo} />
                                <span
                                    style={{
                                        color: '#FFFFFF',
                                        fontFace: 'Inter-Light',
                                        fontSize: '24px',
                                        marginLeft: '10px'
                                    }}
                                >
                                    Twitter
                                </span>
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    window.open(
                                        'https://t.me/trendavatars',
                                        '_blank'
                                    );
                                }}
                            >
                                <ImageIconButton imgToDisplay={telegramLogo} />{' '}
                                <span
                                    style={{
                                        color: '#FFFFFF',
                                        fontWeight: '300',
                                        fontSize: '24px',
                                        marginLeft: '10px'
                                    }}
                                >
                                    Telegram
                                </span>
                            </IconButton>
                            TwitterLogo
                        </Box>
                        <Box
                            style={{
                                border: '1px solid #505050',
                                borderRadius: '32px',
                                padding: '30px',
                                background:
                                    'linear-gradient(101.76deg, rgba(95, 121, 123, 0.1) 16.55%, rgba(53, 90, 90, 0.1) 54.19%, rgba(29, 32, 35, 0.1) 94.97%)',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <Typography
                                style={{
                                    fontFamily: 'Inter',
                                    fontSize: '28px',
                                    fontWeight: '800',
                                    lineHeight: '40px',
                                    letterSpacing: '11%',
                                    textAlign: 'left',
                                    color: '#FFFFFF',
                                    marginRight: '40px'
                                }}
                            >
                                Accelerate your <br /> web3 Advertisement.
                            </Typography>
                            <img
                                src={shineSquares}
                                alt="shine"
                                height="90px"
                                width="90px"
                            />
                        </Box>
                    </Box>
                    <Box sx={{ mt: 15 }}>
                        <Typography
                            style={{
                                fontFamily: 'Inter',
                                fontSize: '36px',
                                fontWeight: '700',
                                lineHeight: '56.8px',
                                textAlign: 'center',
                                color: '#24CD99'
                            }}
                        >
                            Revolutionise Your Startup with Web3 Advertising
                        </Typography>
                        <Typography
                            style={{
                                fontFamily: 'Inter',
                                fontSize: '24px',
                                fontWeight: '400',
                                lineHeight: ' 30px',
                                letterSpacing: '0.025em',
                                textAlign: 'center',
                                color: '#D9D9D9'
                            }}
                        >
                            Let’s Elevate Your Projects!
                        </Typography>
                    </Box>
                    <Box sx={{ mt: 10 }}>
                        <Box sx={{ width: '100%' }}>
                            <Masonry columns={4} spacing={1}>
                                {images.map((item, index) => (
                                    <div key={index}>
                                        <img
                                            srcSet={`${item.src}?w=162&auto=format&dpr=2 2x`}
                                            src={`${item.src}?w=162&auto=format`}
                                            alt={'item.title'}
                                            loading="lazy"
                                            style={{
                                                borderBottomLeftRadius: 4,
                                                borderBottomRightRadius: 4,
                                                display: 'block',
                                                width: '100%'
                                            }}
                                        />
                                    </div>
                                ))}
                            </Masonry>
                        </Box>
                    </Box>
                    {/* -------------------- Another buttons  -------------------- */}
                    <Box
                        style={{
                            marginTop: '50px',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        {anotherButtons.map(buttonLabel => (
                            <Button
                                key={buttonLabel}
                                sx={{
                                    fontSize:
                                        activeButton === buttonLabel ? 14 : 14,
                                    fontWeight:
                                        activeButton === buttonLabel
                                            ? 600
                                            : 500,
                                    fontFamily: 'Inter',
                                    color:
                                        activeButton === buttonLabel
                                            ? 'white'
                                            : '#D9D9D9', // Change color based on active state
                                    background:
                                        activeButton === buttonLabel
                                            ? 'linear-gradient(to right, #269D7E 100%, #24CD99 100%)' // Active button gradient
                                            : 'linear-gradient(101.76deg, rgba(255, 255, 255, 0.1) 16.55%, rgba(131, 131, 131, 0.1) 54.19%, rgba(203, 188, 188, 0.1) 94.97%)', // Default gradient
                                    borderRadius: '14px',
                                    padding: '10px 40px',
                                    margin: '5px',
                                    border: '1px solid #24CD99',
                                    textTransform: 'uppercase'
                                }}
                                onClick={() => handleClick(buttonLabel)}
                            >
                                {buttonLabel}
                            </Button>
                        ))}
                    </Box>
                    <Box
                        sx={{
                            mt: 8
                        }}
                    >
                        <Typography
                            sx={{
                                mb: 3,
                                fontFamily: 'Inter',
                                fontSize: '36px',
                                fontWeight: '700',
                                lineHeight: '76.8px',
                                textAlign: 'center',
                                color: '#24CD99'
                            }}
                        >
                            Advertise your projects in just few easy steps
                        </Typography>
                    </Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={3}>
                            <Box
                                style={{
                                    padding: '15px 25px',
                                    border: '1px solid #D9D9D9',
                                    borderRadius: '14px',
                                    textAlign: 'center',
                                    minHeight: '16rem'
                                }}
                            >
                                <img
                                    src={s1}
                                    alt="twitter"
                                    height="140px"
                                    width="140px"
                                    border="1px solid #D9D9D9"
                                />
                                <Typography
                                    style={{
                                        marginTop: '20px',
                                        fontFamily: 'Inter',
                                        fontSize: '20px',
                                        fontWeight: '500',
                                        lineHeight: '29.05px',
                                        textAlign: 'center',
                                        color: '#D9D9D9'
                                    }}
                                >
                                    Sign up using your username and email
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                            <Box
                                style={{
                                    padding: '15px 25px',
                                    border: '1px solid #D9D9D9',
                                    borderRadius: '14px',
                                    textAlign: 'center',
                                    minHeight: '16rem'
                                }}
                            >
                                <img
                                    src={s2}
                                    alt="twitter"
                                    height="140px"
                                    width="140px"
                                    border="1px solid #D9D9D9"
                                />
                                <Typography
                                    style={{
                                        marginTop: '20px',
                                        fontFamily: 'Inter',
                                        fontSize: '20px',
                                        fontWeight: '500',
                                        lineHeight: '29.05px',
                                        textAlign: 'center',
                                        color: '#D9D9D9'
                                    }}
                                >
                                    Choose your preferred ads bundle
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                            <Box
                                style={{
                                    padding: '15px 25px',
                                    border: '1px solid #D9D9D9',
                                    borderRadius: '14px',
                                    textAlign: 'center',
                                    minHeight: '16rem'
                                }}
                            >
                                <img
                                    src={s3}
                                    alt="twitter"
                                    height="140px"
                                    width="140px"
                                    border="1px solid #D9D9D9"
                                />
                                <Typography
                                    style={{
                                        marginTop: '20px',
                                        fontFamily: 'Inter',
                                        fontSize: '20px',
                                        fontWeight: '500',
                                        lineHeight: '29.05px',
                                        textAlign: 'center',
                                        color: '#D9D9D9'
                                    }}
                                >
                                    Choose your preferred ads bundle
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Box
                                style={{
                                    padding: '15px 25px',
                                    border: '1px solid #D9D9D9',
                                    borderRadius: '14px',
                                    textAlign: 'center',
                                    minHeight: '16rem'
                                }}
                            >
                                <img
                                    src={s4}
                                    alt="twitter"
                                    height="140px"
                                    width="140px"
                                    border="1px solid #D9D9D9"
                                />
                                <Typography
                                    style={{
                                        marginTop: '20px',
                                        fontFamily: 'Inter',
                                        fontSize: '20px',
                                        fontWeight: '500',
                                        lineHeight: '29.05px',
                                        textAlign: 'center',
                                        color: '#D9D9D9'
                                    }}
                                >
                                    Boost your advertising efforts and watch
                                    your sales grow.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={{ mt: 12 }}>
                        <Grid item xs={12} sm={6} md={5}>
                            <Typography
                                style={{
                                    fontFamily: 'Inter',
                                    fontSize: '80px',
                                    fontWeight: '800',
                                    lineHeight: '95px',
                                    textAlign: 'left',
                                    color: '#D9D9D9'
                                }}
                            >
                                Benefits for Viewers
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6} md={7}>
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6}>
                                        <Box
                                            style={{
                                                border: '1px solid #D9D9D9',
                                                padding: '20px',
                                                borderRadius: '14px',
                                                margin: '5px'
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    color: '#D9D9D9'
                                                }}
                                            >
                                                1. Connect Wallet
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Box
                                            style={{
                                                border: '1px solid #D9D9D9',
                                                padding: '20px',
                                                borderRadius: '14px',
                                                margin: '5px'
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    color: '#D9D9D9'
                                                }}
                                            >
                                                2. View Ads
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Box
                                            style={{
                                                border: '1px solid #D9D9D9',
                                                padding: '20px',
                                                borderRadius: '14px',
                                                margin: '5px'
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    color: '#D9D9D9'
                                                }}
                                            >
                                                3. Earn Rewards Points
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Box
                                            style={{
                                                border: '1px solid #D9D9D9',
                                                padding: '20px',
                                                borderRadius: '14px',
                                                margin: '5px'
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    color: '#D9D9D9'
                                                }}
                                            >
                                                4. Redeem points to TrendToken
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box sx={{ mt: 10 }}>
                        <Typography
                            style={{
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                fontWeight: '400',
                                lineHeight: ' 15px',
                                letterSpacing: '0.025em',
                                textAlign: 'center',
                                color: '#D9D9D9'
                            }}
                        >
                            Products
                        </Typography>
                        <Typography
                            style={{
                                fontFamily: 'Inter',
                                fontSize: '36px',
                                fontWeight: '700',
                                lineHeight: '50px',
                                textAlign: 'center',
                                color: '#24CD99'
                            }}
                        >
                            Explore Trend Products
                        </Typography>
                    </Box>
                    <Grid container spacing={1} sx={{ mt: 2, p: 5, pt: 2 }}>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <Box sx={{ p: 2, bgcolor: '#181818' }}>
                                <Stack sx={{ minHeight: '11rem', maxHeight: '11rem' }}>
                                    <img
                                        src={productSec2}
                                        alt="Ai powerd platform"
                                    />

                                    <Typography
                                        sx={{
                                            mt: 2,
                                            fontFamily: 'Inter',
                                            fontSize: '12px',
                                            fontWeight: '400',
                                            lineHeight: '8px',
                                            textAlign: 'center',
                                            color: '#D9D9D9'
                                        }}
                                    >
                                        Get your Website Designed and Published
                                    </Typography>
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            fontFamily: 'Inter',
                                            fontSize: '12px',
                                            fontWeight: '400',
                                            lineHeight: '8px',
                                            textAlign: 'center',
                                            color: '#D9D9D9'
                                        }}
                                    >
                                        using Ai, with TrendifyWeb.
                                    </Typography>
                                </Stack>
                                <Stack
                                    style={{
                                        marginTop: '20px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Button
                                        sx={btnStyleGreenWhite}
                                        onClick={() => {
                                            window.open(
                                                'https://trendifyweb.ai/',
                                                '_blank'
                                            );
                                        }}
                                    >
                                        Checkout Now
                                    </Button>
                                </Stack>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <Box sx={{ p: 2, bgcolor: '#181818' }}>
                                <Stack sx={{ minHeight: '11rem', maxHeight: '11rem' }}>
                                    <img
                                        src={productSec3}
                                        alt="Ai powerd platform"
                                    />

                                    <Typography
                                        sx={{
                                            mt: 2,
                                            fontFamily: 'Inter',
                                            fontSize: '12px',
                                            fontWeight: '400',
                                            lineHeight: '8px',
                                            textAlign: 'center',
                                            color: '#D9D9D9'
                                        }}
                                    >
                                        Trendify Tokens : Empowering Innovations -
                                    </Typography>
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            fontFamily: 'Inter',
                                            fontSize: '12px',
                                            fontWeight: '400',
                                            lineHeight: '8px',
                                            textAlign: 'center',
                                            color: '#D9D9D9'
                                        }}
                                    >
                                        Your Gateway to the Future of Crypto.
                                    </Typography>
                                </Stack>
                                <Stack
                                    style={{
                                        marginTop: '20px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Button
                                        sx={btnStyleGreenWhite}
                                        onClick={() => {
                                            window.open(
                                                'https://trendifytokens.io/',
                                                '_blank'
                                            );
                                        }}
                                    >
                                        Checkout Now
                                    </Button>
                                </Stack>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <Box sx={{ p: 2, bgcolor: '#181818' }}>
                                <Stack sx={{ minHeight: '11rem', maxHeight: '11rem' }}>
                                    <img
                                        src={productSec4}
                                        alt="Ai powerd platform"
                                    />

                                    <Typography
                                        sx={{
                                            mt: 2,
                                            fontFamily: 'Inter',
                                            fontSize: '12px',
                                            fontWeight: '400',
                                            lineHeight: '8px',
                                            textAlign: 'center',
                                            color: '#D9D9D9'
                                        }}
                                    >
                                        Swap your any Coin with your favourite one
                                    </Typography>
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            fontFamily: 'Inter',
                                            fontSize: '12px',
                                            fontWeight: '400',
                                            lineHeight: '8px',
                                            textAlign: 'center',
                                            color: '#D9D9D9'
                                        }}
                                    >
                                        now with Trenddx.
                                    </Typography>
                                </Stack>
                                <Stack
                                    style={{
                                        marginTop: '20px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Button
                                        sx={btnStyleGreenWhite}
                                        onClick={() => {
                                            window.open(
                                                'https://trenddx.io/',
                                                '_blank'
                                            );
                                        }}
                                    >
                                        Checkout Now
                                    </Button>
                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} sx={{ mt: 5, p: 10 }}>
                        <Grid item xs={12} sm={5.5}>
                            <Box
                                style={{
                                    position: 'relative'
                                }}
                            >
                                <img
                                    src={viewerBackground}
                                    style={{
                                        width: '100%',
                                        objectFit: 'cover',
                                        opacity: '0.5',
                                        height: '15rem',
                                        borderRadius: '14px',
                                        border: '1px solid #505050'
                                    }}
                                    alt=""
                                />

                                <Typography
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)', // Center text
                                        color: '#FFFFFF',
                                        width: '100%',
                                        fontWeight: 'bold',
                                        fontFamily: 'Inter',
                                        fontSize: '32px',
                                        fontWeight: '800',
                                        lineHeight: '48.41px',
                                        textAlign: 'center',
                                        color: '#FFFFFF'
                                    }}
                                >
                                    CONNECT AS VIEWER
                                </Typography>
                                <Button
                                    sx={{
                                        position: 'absolute',
                                        // top: '50%',
                                        bottom: '10%',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        fontSize: 20,
                                        fontWeight: 500,
                                        fontFamily: 'Inter',
                                        color: 'white',
                                        background:
                                            'linear-gradient(to right, #269D7E 100%, #24CD99 100%)',

                                        borderRadius: '99px',
                                        padding: '10px 30px',
                                        margin: '5px',
                                        border: '1px solid #24CD99',
                                        textTransform: 'none'
                                    }}
                                    onClick={() =>
                                        handleClick('connectAsViewer')
                                    }
                                >
                                    Connect Now
                                </Button>
                            </Box>
                        </Grid>

                        <Grid item xs={1}></Grid>

                        <Grid item xs={12} sm={5.5}>
                            <Box
                                style={{
                                    position: 'relative'
                                }}
                            >
                                <img
                                    src={advertiserBackground}
                                    style={{
                                        width: '100%',
                                        objectFit: 'cover',
                                        opacity: '0.5',
                                        height: '15rem',
                                        borderRadius: '14px',
                                        border: '1px solid #505050'
                                    }}
                                    alt=""
                                />

                                <Typography
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)', // Center text
                                        color: '#FFFFFF',
                                        width: '100%',

                                        fontWeight: 'bold',

                                        fontFamily: 'Inter',
                                        fontSize: '32px',
                                        fontWeight: '800',
                                        lineHeight: '48.41px',
                                        textAlign: 'center',
                                        color: '#FFFFFF'
                                    }}
                                >
                                    CONNECT AS ADVERTISER
                                </Typography>
                                <Button
                                    sx={{
                                        position: 'absolute',
                                        // top: '50%',
                                        bottom: '10%',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        fontSize: 20,
                                        fontWeight: 500,
                                        fontFamily: 'Inter',
                                        color: 'white',
                                        background:
                                            'linear-gradient(to right, #269D7E 100%, #24CD99 100%)',

                                        borderRadius: '99px',
                                        padding: '10px 30px',
                                        margin: '5px',
                                        border: '1px solid #24CD99',
                                        textTransform: 'none'
                                    }}
                                    onClick={() =>
                                        handleClick('connectAsAdvertiser')
                                    }
                                >
                                    Connect Now
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={1}
                        sx={{ mt: 5, position: 'relative' }}
                    >
                        <Grid item xs={12}>
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <Button
                                    style={{
                                        color: '#24CD99',
                                        fontFamily: 'Inter',
                                        fontSize: '28px',
                                        fontWeight: '400',
                                        lineHeight: '43.57px',
                                        textAlign: 'left'
                                    }}
                                >
                                    <img
                                        src={trendLogo}
                                        alt="logo"
                                        style={{
                                            width: '300px'
                                        }}
                                    />
                                </Button>
                            </Box>
                        </Grid>
                        {/* <Grid item xs={7}>
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography
                                    style={{
                                        color: '#FFFFFF',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        handleClick('Viewers');
                                    }}
                                >
                                    Viewers
                                </Typography>
                                <Typography
                                    style={{
                                        color: '#FFFFFF',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        handleClick('Advertisers');
                                    }}
                                >
                                    Advertisers
                                </Typography>
                                <Typography
                                    style={{
                                        color: '#FFFFFF',
                                        visibility: 'hidden'
                                    }}
                                >
                                    Advertising Bundles
                                </Typography>
                                <Typography
                                    style={{
                                        color: '#FFFFFF',
                                        visibility: 'hidden'
                                    }}
                                >
                                    Docs
                                </Typography>
                            </Box>
                        </Grid> */}
                    </Grid>

                    <Grid container spacint={1} sx={{ mt: 4 }}>
                        <Grid item xs={12}>
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <IconButton
                                    onClick={() => {
                                        window.open(
                                            'https://twitter.com/TTAvatars',
                                            '_blank'
                                        );
                                    }}
                                >
                                    <img
                                        src={twitterLogo}
                                        alt="twitter"
                                        height="auto"
                                        width="40rem"
                                    />
                                </IconButton>

                                <IconButton
                                    onClick={() => {
                                        window.open(
                                            'https://t.me/trendavatars',
                                            '_blank'
                                        );
                                    }}
                                >
                                    <img
                                        src={telegramLogo}
                                        alt="twitter"
                                        height="auto"
                                        width="40rem"
                                        style={{
                                            marginLeft: '30px'
                                        }}
                                    />
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container spacint={1} sx={{ mt: 4 }}>
                        <Grid item xs={12}>
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography
                                    style={{
                                        color: '#24CD99',
                                        fontFamily: 'Inter',
                                        fontSize: '20px',
                                        fontWeight: '500',
                                        lineHeight: '26.04px',
                                        textAlign: 'left'
                                    }}
                                >
                                    Terms of Services
                                </Typography>

                                <Typography
                                    style={{
                                        color: '#24CD99',
                                        fontFamily: 'Inter',
                                        fontSize: '20px',
                                        fontWeight: '500',
                                        lineHeight: '26.04px',
                                        marginLeft: '5rem'
                                    }}
                                >
                                    Privacy Policy
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                style={{
                                    color: '#FFFFFF',
                                    textAlign: 'center',
                                    marginRigh: '20px',
                                    paddingRight: '20px'
                                }}
                            >
                                &#169; {currentYear} Trend Web3 Ads, All right
                                Reserved.
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            )}
            {/* mobile section  ============================================================================== */}

            {smMatched && (
                <>
                    <Container
                        maxWidth="sm"
                        style={{
                            height: '100%',
                            position: 'relative',
                            padding: '15px'
                            // border: '2px dashed yellow',
                        }}
                    >
                        <IconButton onClick={toggleMenu}>
                            <MenuIcon sx={{ color: 'white' }} />{' '}
                            {/* Adjust color as needed */}
                        </IconButton>
                        <StyledMenu className={isOpen ? 'open' : ''}>
                            <List>
                                <ListItem>
                                    <ListItemIcon>
                                        <img
                                            src={viewer}
                                            alt="viewer"
                                            height="auto"
                                            width="40px"
                                            style={{
                                                backgroundColor: '#24CD99'
                                            }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        onClick={() => {
                                            handleClick('Viewers');
                                            onClose();
                                        }}
                                        sx={{ color: 'white' }}
                                        primary="Viewers"
                                        primaryTypographyProps={{
                                            fontSize: '20px'
                                        }}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <img
                                            src={advertiser}
                                            alt="viewer"
                                            height="auto"
                                            width="40px"
                                            style={{
                                                backgroundColor: '#24CD99'
                                            }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        onClick={() => {
                                            handleClick('Advertisers');
                                            onClose();
                                        }}
                                        sx={{ color: 'white' }}
                                        primary="Advertisers"
                                        primaryTypographyProps={{
                                            fontSize: '20px'
                                        }}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <img
                                            src={bundle}
                                            alt="viewer"
                                            height="auto"
                                            width="40px"
                                            style={{
                                                backgroundColor: '#24CD99'
                                            }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        onClick={onClose}
                                        sx={{
                                            color: 'white',
                                            textAlign: 'center'
                                        }}
                                        primary="Advertising Bundles"
                                        primaryTypographyProps={{
                                            fontSize: '20px'
                                        }}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <img
                                            src={docs}
                                            alt="viewer"
                                            height="auto"
                                            width="40px"
                                            style={{
                                                backgroundColor: '#24CD99'
                                            }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        onClick={onClose}
                                        sx={{ color: 'white' }}
                                        primary="Docs"
                                        primaryTypographyProps={{
                                            fontSize: '20px'
                                        }}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <img
                                            src={connect}
                                            alt="viewer"
                                            height="auto"
                                            width="40px"
                                            style={{
                                                backgroundColor: '#24CD99'
                                            }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        onClick={onClose}
                                        sx={{ color: 'white' }}
                                        primary="Connect"
                                        primaryTypographyProps={{
                                            fontSize: '20px'
                                        }}
                                    />
                                </ListItem>
                                {/* Add more menu items here */}
                            </List>
                        </StyledMenu>

                        <Box>
                            {/* box for language button and google translate */}
                            <Box
                                style={{
                                    position: 'absolute',
                                    top: '10px',
                                    right: '10px'
                                }}
                            >
                                <Button
                                    sx={{
                                        zIndex: '10',
                                        fontSize:
                                            activeButton === 'mobLangButton'
                                                ? 14
                                                : 14,
                                        fontWeight:
                                            activeButton === 'mobLangButton'
                                                ? 600
                                                : 500,
                                        fontFamily: 'Inter',
                                        color:
                                            activeButton === 'mobLangButton'
                                                ? 'white'
                                                : '#D9D9D9', // Change color based on active state
                                        background: showMobGoogleTranslate
                                            ? 'linear-gradient(to right, #269D7E 100%, #24CD99 100%)' // Active button gradient
                                            : 'linear-gradient(101.76deg, rgba(255, 255, 255, 0.1) 16.55%, rgba(131, 131, 131, 0.1) 54.19%, rgba(203, 188, 188, 0.1) 94.97%)', // Default gradient
                                        borderRadius: '99px',
                                        padding: '10px 20px',
                                        margin: '5px',
                                        border: '1px solid #505050',
                                        textTransform: 'none'
                                    }}
                                    onClick={() => handleClick('mobLangButton')}
                                >
                                    <TranslateIcon />
                                </Button>
                                {/* ========================================= */}
                                {/* Google Translate element */}
                            </Box>

                            <Box
                                style={{
                                    position: 'absolute',
                                    top: '15px',
                                    right: '90px'
                                }}
                            >
                                <div
                                    style={{
                                        display: showMobGoogleTranslate
                                            ? 'block'
                                            : 'none',
                                        zIndex: '30',
                                        backgroundColor: '#269D7E',
                                        padding: '0px 8px',

                                        borderRadius: '8px'
                                    }}
                                    id="google_translate_element"
                                ></div>
                            </Box>

                            {/* {buttons.map(buttonLabel => (
                            <Button
                                key={buttonLabel}
                                sx={{
                                    zIndex: '10',
                                    fontSize:
                                        activeButton === buttonLabel ? 14 : 14,
                                    fontWeight:
                                        activeButton === buttonLabel
                                            ? 600
                                            : 500,
                                    fontFamily: 'Inter',
                                    color:
                                        activeButton === buttonLabel
                                            ? 'white'
                                            : '#D9D9D9', // Change color based on active state
                                    background:
                                        activeButton === buttonLabel
                                            ? 'linear-gradient(to right, #269D7E 100%, #24CD99 100%)' // Active button gradient
                                            : 'linear-gradient(101.76deg, rgba(255, 255, 255, 0.1) 16.55%, rgba(131, 131, 131, 0.1) 54.19%, rgba(203, 188, 188, 0.1) 94.97%)', // Default gradient
                                    borderRadius: '99px',
                                    padding: '10px 20px',
                                    margin: '5px',
                                    border: '1px solid #505050',
                                    textTransform: 'none'
                                }}
                                onClick={() => handleClick(buttonLabel)}
                            >
                                {buttonLabel}
                            </Button>
                        ))} */}
                        </Box>

                        <Box
                            style={{
                                maxwWdth: '100%'
                            }}
                        >
                            <img
                                src={web3}
                                alt="image Web3"
                                style={{
                                    opacity: '0.8',
                                    zIndex: '-20',
                                    width: '100%',
                                    height: 'auto',
                                    position: 'absolute',
                                    top: '20px',
                                    left: '0px',
                                    objectFit: 'conver'
                                }}
                            />
                            <Typography
                                sx={{
                                    color: '#24CD99',
                                    textAlign: 'center',
                                    fontSize: '40px',
                                    fontWeight: 800,
                                    mt: 3
                                }}
                            >
                                Trend Web3 Ads
                            </Typography>

                            <Divider
                                variant="middle"
                                style={{ backgroundColor: 'grey' }}
                                sx={{ mb: 3 }}
                            />

                            <Box
                                sx={{
                                    maxWidth: '100%',

                                    gap: '7px',
                                    opacity: '0px'
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: 'Inter',
                                        fontSize: '28px',
                                        fontWeight: 700,
                                        lineHeight: '50px',
                                        letterSpacing: '0.06em',
                                        textAlign: 'center',
                                        color: '#D9D9D9'
                                    }}
                                >
                                    Transforming the <br /> digital advertising
                                    landscape <br />
                                    with{' '}
                                    <span style={{ color: '#24CD99' }}>
                                        web3
                                    </span>{' '}
                                    technology
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Inter',
                                        fontSize: '12px',
                                        fontWeight: '400',
                                        lineHeight: '29.05px',
                                        letterSpacing: '0.18em',
                                        textAlign: 'center',
                                        color: '#929292'
                                    }}
                                >
                                    The Future of Marketing is Here !
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                position: 'relative',
                                mt: 2,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <Button
                                sx={{
                                    fontSize:
                                        activeButton === 'start advertising'
                                            ? 14
                                            : 14,
                                    fontWeight:
                                        activeButton === 'start advertising'
                                            ? 600
                                            : 500,
                                    fontFamily: 'Inter',
                                    color:
                                        activeButton === 'start advertising'
                                            ? 'white'
                                            : '#D9D9D9', // Change color based on active state
                                    background:
                                        activeButton === 'start advertising'
                                            ? 'linear-gradient(to right, #269D7E 100%, #24CD99 100%)' // Active button gradient
                                            : 'linear-gradient(101.76deg, rgba(255, 255, 255, 0.1) 16.55%, rgba(131, 131, 131, 0.1) 54.19%, rgba(203, 188, 188, 0.1) 94.97%)', // Default gradient
                                    borderRadius: '17px',
                                    padding: '10px 35px',

                                    border: '1px solid #505050',
                                    textTransform: 'uppercase'
                                }}
                                onClick={() => handleClick('start advertising')}
                            >
                                Start Advertising
                            </Button>
                        </Box>
                        <Box
                            sx={{
                                m: '2px auto',
                                mt: 5,
                                position: 'relative',
                                maxWidth: '85%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <IconButton
                                onClick={() => {
                                    window.open(
                                        'https://twitter.com/TTAvatars',
                                        '_blank'
                                    );
                                }}
                            >
                                <ImageIconButton imgToDisplay={twitterLogo} />
                                <span
                                    style={{
                                        color: '#FFFFFF',
                                        fontFace: 'Inter-Light',
                                        fontSize: '24px',
                                        marginLeft: '10px'
                                    }}
                                >
                                    Twitter
                                </span>
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    window.open(
                                        'https://t.me/trendavatars',
                                        '_blank'
                                    );
                                }}
                            >
                                <ImageIconButton imgToDisplay={telegramLogo} />{' '}
                                <span
                                    style={{
                                        color: '#FFFFFF',
                                        fontWeight: '300',
                                        fontSize: '24px',
                                        marginLeft: '10px'
                                    }}
                                >
                                    Telegram
                                </span>
                            </IconButton>
                        </Box>
                        <Box
                            sx={{
                                mt: 6,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <Box
                                style={{
                                    border: '1px solid #505050',
                                    borderRadius: '32px',
                                    padding: '15px',
                                    background:
                                        'linear-gradient(101.76deg, rgba(95, 121, 123, 0.1) 16.55%, rgba(53, 90, 90, 0.1) 54.19%, rgba(29, 32, 35, 0.1) 94.97%)',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography
                                    style={{
                                        fontFamily: 'Inter',
                                        fontSize: '24px',
                                        fontWeight: '500',
                                        lineHeight: '40px',
                                        letterSpacing: '9%',
                                        textAlign: 'center',
                                        color: '#FFFFFF'
                                    }}
                                >
                                    Accelerate your <br /> web3 Advertisement.
                                </Typography>
                                <img
                                    src={shineSquares}
                                    alt="shine"
                                    height="90px"
                                    width="90px"
                                />
                            </Box>
                        </Box>

                        <Box sx={{ mt: 10 }}>
                            <Typography
                                style={{
                                    fontFamily: 'Inter',
                                    fontSize: '32px',
                                    fontWeight: '700',
                                    lineHeight: '56.8px',
                                    textAlign: 'center',
                                    color: '#24CD99'
                                }}
                            >
                                Revolutionise Your Startup with Web3 Advertising
                            </Typography>
                            <Typography
                                style={{
                                    fontFamily: 'Inter',
                                    fontSize: '20px',
                                    fontWeight: '400',
                                    lineHeight: ' 30px',
                                    letterSpacing: '0.025em',
                                    textAlign: 'center',
                                    color: '#D9D9D9'
                                }}
                            >
                                Let’s Elevate Your Projects!
                            </Typography>
                        </Box>
                        <Box sx={{ mt: 10 }}>
                            <Box sx={{ width: '100%' }}>
                                <Masonry columns={2} spacing={1}>
                                    {images.map((item, index) => (
                                        <div key={index}>
                                            <img
                                                srcSet={`${item.src}?w=162&auto=format&dpr=2 2x`}
                                                src={`${item.src}?w=162&auto=format`}
                                                alt={'item.title'}
                                                loading="lazy"
                                                style={{
                                                    borderBottomLeftRadius: 4,
                                                    borderBottomRightRadius: 4,
                                                    display: 'block',
                                                    width: '100%'
                                                }}
                                            />
                                        </div>
                                    ))}
                                </Masonry>
                            </Box>
                        </Box>
                        {/* -------------------- Another buttons  -------------------- */}
                        <Box
                            style={{
                                marginTop: '30px',
                                width: '100%'
                            }}
                        >
                            <Grid
                                container
                                spacing={2}
                                sx={{
                                    display: 'flex',
                                    flexwrap: 'wrap',
                                    justifyContent: 'center'
                                }}
                            >
                                {anotherButtons.map(buttonLabel => (
                                    <Button
                                        key={buttonLabel}
                                        sx={{
                                            fontSize:
                                                activeButton === buttonLabel
                                                    ? 14
                                                    : 14,
                                            fontWeight:
                                                activeButton === buttonLabel
                                                    ? 600
                                                    : 500,
                                            fontFamily: 'Inter',
                                            color:
                                                activeButton === buttonLabel
                                                    ? 'white'
                                                    : '#D9D9D9', // Change color based on active state
                                            background:
                                                activeButton === buttonLabel
                                                    ? 'linear-gradient(to right, #269D7E 100%, #24CD99 100%)' // Active button gradient
                                                    : 'linear-gradient(101.76deg, rgba(255, 255, 255, 0.1) 16.55%, rgba(131, 131, 131, 0.1) 54.19%, rgba(203, 188, 188, 0.1) 94.97%)', // Default gradient
                                            borderRadius: '14px',
                                            padding: '10px 40px',
                                            margin: '5px',
                                            border: '1px solid #24CD99',
                                            textTransform: 'uppercase'
                                        }}
                                        onClick={() => handleClick(buttonLabel)}
                                    >
                                        {buttonLabel}
                                    </Button>
                                ))}
                            </Grid>
                        </Box>
                        <Box
                            sx={{
                                mt: 8
                            }}
                        >
                            <Typography
                                sx={{
                                    mb: 4,
                                    fontFamily: 'Inter',
                                    fontSize: '28px',
                                    fontWeight: '600',
                                    lineHeight: '36.8px',
                                    textAlign: 'center',
                                    color: '#24CD99'
                                }}
                            >
                                Advertise your projects <br /> in just few easy
                                steps
                            </Typography>
                        </Box>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Box
                                    style={{
                                        padding: '15px 25px',
                                        border: '1px solid #D9D9D9',
                                        borderRadius: '14px',
                                        textAlign: 'center',
                                        minHeight: '14rem'
                                    }}
                                >
                                    <img
                                        src={s1}
                                        alt="twitter"
                                        height="120px"
                                        width="120px"
                                        border="1px solid #D9D9D9"
                                    />
                                    <Typography
                                        style={{
                                            marginTop: '20px',
                                            fontFamily: 'Inter',
                                            fontSize: '20px',
                                            fontWeight: '400',
                                            lineHeight: '29.05px',
                                            textAlign: 'center',
                                            color: '#D9D9D9'
                                        }}
                                    >
                                        Sign up using your username and email
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box
                                    style={{
                                        padding: '15px 25px',
                                        border: '1px solid #D9D9D9',
                                        borderRadius: '14px',
                                        textAlign: 'center',
                                        minHeight: '14rem'
                                    }}
                                >
                                    <img
                                        src={s2}
                                        alt="twitter"
                                        height="120px"
                                        width="120px"
                                        border="1px solid #D9D9D9"
                                    />
                                    <Typography
                                        style={{
                                            marginTop: '20px',
                                            fontFamily: 'Inter',
                                            fontSize: '20px',
                                            fontWeight: '400',
                                            lineHeight: '29.05px',
                                            textAlign: 'center',
                                            color: '#D9D9D9'
                                        }}
                                    >
                                        Choose your preferred ads bundle
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box
                                    style={{
                                        padding: '15px 25px',
                                        border: '1px solid #D9D9D9',
                                        borderRadius: '14px',
                                        textAlign: 'center',
                                        minHeight: '14rem'
                                    }}
                                >
                                    <img
                                        src={s3}
                                        alt="twitter"
                                        height="120px"
                                        width="120px"
                                        border="1px solid #D9D9D9"
                                    />
                                    <Typography
                                        style={{
                                            marginTop: '20px',
                                            fontFamily: 'Inter',
                                            fontSize: '20px',
                                            fontWeight: '400',
                                            lineHeight: '29.05px',
                                            textAlign: 'center',
                                            color: '#D9D9D9'
                                        }}
                                    >
                                        Choose your preferred ads bundle
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box
                                    style={{
                                        padding: '15px 25px',
                                        border: '1px solid #D9D9D9',
                                        borderRadius: '14px',
                                        textAlign: 'center',
                                        minHeight: '14rem'
                                    }}
                                >
                                    <img
                                        src={s4}
                                        alt="twitter"
                                        height="100px"
                                        width="120px"
                                        border="1px solid #D9D9D9"
                                    />
                                    <Typography
                                        style={{
                                            marginTop: '20px',
                                            fontFamily: 'Inter',
                                            fontSize: '20px',
                                            fontWeight: '500',
                                            lineHeight: '29.05px',
                                            textAlign: 'center',
                                            color: '#D9D9D9'
                                        }}
                                    >
                                        Boost your advertising efforts and watch
                                        your sales grow.
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} sx={{ mt: 12 }}>
                            <Grid item xs={12}>
                                <Typography
                                    style={{
                                        fontFamily: 'Inter',
                                        fontSize: '50px',
                                        fontWeight: '600',
                                        lineHeight: '55px',
                                        textAlign: 'center',
                                        color: '#D9D9D9'
                                    }}
                                >
                                    Benefits for Viewers
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Box
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Box
                                                style={{
                                                    height: '3.25rem',
                                                    border: '1px solid #D9D9D9',
                                                    padding: '20px',
                                                    borderRadius: '14px',
                                                    margin: '5px'
                                                }}
                                            >
                                                <Typography
                                                    style={{
                                                        textAlign: 'center',
                                                        color: '#D9D9D9'
                                                    }}
                                                >
                                                    1.
                                                    <br /> Connect Wallet
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box
                                                style={{
                                                    height: '3.25rem',
                                                    border: '1px solid #D9D9D9',
                                                    padding: '20px',
                                                    borderRadius: '14px',
                                                    margin: '5px'
                                                }}
                                            >
                                                <Typography
                                                    style={{
                                                        textAlign: 'center',
                                                        color: '#D9D9D9'
                                                    }}
                                                >
                                                    2.
                                                    <br /> View Ads
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box
                                                style={{
                                                    height: '3.25rem',
                                                    border: '1px solid #D9D9D9',
                                                    padding: '20px',
                                                    borderRadius: '14px',
                                                    margin: '5px'
                                                }}
                                            >
                                                <Typography
                                                    style={{
                                                        textAlign: 'center',
                                                        color: '#D9D9D9'
                                                    }}
                                                >
                                                    3.
                                                    <br /> Earn Rewards Points
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box
                                                style={{
                                                    height: '3.25rem',
                                                    border: '1px solid #D9D9D9',
                                                    padding: '20px',
                                                    borderRadius: '14px',
                                                    margin: '5px'
                                                }}
                                            >
                                                <Typography
                                                    style={{
                                                        textAlign: 'center',
                                                        color: '#D9D9D9'
                                                    }}
                                                >
                                                    4.
                                                    <br /> Redeem points to
                                                    TrendToken
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>

                        <Box sx={{ mt: 10 }}>
                            <Typography
                                style={{
                                    fontFamily: 'Inter',
                                    fontSize: '14px',
                                    fontWeight: '400',
                                    lineHeight: ' 15px',
                                    letterSpacing: '0.025em',
                                    textAlign: 'center',
                                    color: '#D9D9D9'
                                }}
                            >
                                Products
                            </Typography>
                            <Typography
                                style={{
                                    fontFamily: 'Inter',
                                    fontSize: '24px',
                                    fontWeight: '700',
                                    lineHeight: '50px',
                                    textAlign: 'center',
                                    color: '#24CD99'
                                }}
                            >
                                Explore Trend Products
                            </Typography>
                        </Box>
                        <Grid container spacing={1} sx={{ mt: 2, p: 2, pt: 2 }}>
                            <Grid item xs={12} sm={6} md={6} lg={4}>
                                <Box sx={{ p: 2, bgcolor: '#181818' }}>
                                    <Stack sx={{ minHeight: '11rem', maxHeight: '11rem' }}>
                                        <img
                                            src={productSec2}
                                            alt="Ai powerd platform"
                                        />

                                        <Typography
                                            sx={{
                                                mt: 2,
                                                fontFamily: 'Inter',
                                                fontSize: '12px',
                                                fontWeight: '400',
                                                lineHeight: '8px',
                                                textAlign: 'center',
                                                color: '#D9D9D9'
                                            }}
                                        >
                                            Get your Website Designed and Published
                                        </Typography>
                                        <Typography
                                            sx={{
                                                mt: 2,
                                                fontFamily: 'Inter',
                                                fontSize: '12px',
                                                fontWeight: '400',
                                                lineHeight: '8px',
                                                textAlign: 'center',
                                                color: '#D9D9D9'
                                            }}
                                        >
                                            using Ai, with TrendifyWeb.
                                        </Typography>
                                    </Stack>
                                    <Stack
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Button
                                            sx={btnStyleGreenWhite}
                                            style={{
                                                fontSize: '16px'
                                            }}
                                            onClick={() => {
                                                window.open(
                                                    'https://trendifyweb.ai/',
                                                    '_blank'
                                                );
                                            }}
                                        >
                                            Checkout Now
                                        </Button>
                                    </Stack>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={4}>
                                <Box sx={{ p: 2, bgcolor: '#181818' }}>
                                    <Stack sx={{ minHeight: '11rem', maxHeight: '11rem' }}>
                                        <img
                                            src={productSec3}
                                            alt="Ai powerd platform"
                                        />

                                        <Typography
                                            sx={{
                                                mt: 2,
                                                fontFamily: 'Inter',
                                                fontSize: '12px',
                                                fontWeight: '400',
                                                lineHeight: '8px',
                                                textAlign: 'center',
                                                color: '#D9D9D9'
                                            }}
                                        >
                                            Trendify Tokens : Empowering Innovations -
                                        </Typography>
                                        <Typography
                                            sx={{
                                                mt: 2,
                                                fontFamily: 'Inter',
                                                fontSize: '12px',
                                                fontWeight: '400',
                                                lineHeight: '8px',
                                                textAlign: 'center',
                                                color: '#D9D9D9'
                                            }}
                                        >
                                            Your Gateway to the Future of Crypto.
                                        </Typography>

                                    </Stack>
                                    <Stack
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Button
                                            sx={btnStyleGreenWhite}
                                            style={{
                                                fontSize: '16px'
                                            }}
                                            onClick={() => {
                                                window.open(
                                                    'https://trendifytokens.io/',
                                                    '_blank'
                                                );
                                            }}
                                        >
                                            Checkout Now
                                        </Button>
                                    </Stack>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={4}>
                                <Box sx={{ p: 2, bgcolor: '#181818' }}>
                                    <Stack sx={{ minHeight: '11rem', maxHeight: '11rem' }}>
                                        <img
                                            src={productSec4}
                                            alt="Ai powerd platform"
                                        />

                                        <Typography
                                            sx={{
                                                mt: 2,
                                                fontFamily: 'Inter',
                                                fontSize: '12px',
                                                fontWeight: '400',
                                                lineHeight: '8px',
                                                textAlign: 'center',
                                                color: '#D9D9D9'
                                            }}
                                        >
                                            Swap your any Coin with your favourite one
                                        </Typography>
                                        <Typography
                                            sx={{
                                                mt: 2,
                                                fontFamily: 'Inter',
                                                fontSize: '12px',
                                                fontWeight: '400',
                                                lineHeight: '8px',
                                                textAlign: 'center',
                                                color: '#D9D9D9'
                                            }}
                                        >
                                            now with Trenddx.
                                        </Typography>
                                    </Stack>
                                    <Stack
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Button
                                            sx={btnStyleGreenWhite}
                                            style={{
                                                fontSize: '16px'
                                            }}
                                            onClick={() => {
                                                window.open(
                                                    'https://trenddx.io/',
                                                    '_blank'
                                                );
                                            }}
                                        >
                                            Checkout Now
                                        </Button>
                                    </Stack>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container spacing={1} sx={{ mt: 5 }}>
                            <Grid item xs={12}>
                                <Box
                                    style={{
                                        position: 'relative'
                                    }}
                                >
                                    <img
                                        src={viewerBackground}
                                        style={{
                                            width: '100%',
                                            objectFit: 'cover',
                                            opacity: '0.5',
                                            height: '15rem',
                                            borderRadius: '14px',
                                            border: '1px solid #505050'
                                        }}
                                        alt=""
                                    />

                                    <Typography
                                        style={{
                                            position: 'absolute',
                                            top: '30%',
                                            left: '30%',
                                            transform: 'translate(-30%, -30%)', // Center text
                                            color: '#FFFFFF',
                                            width: '100%',
                                            fontWeight: 'bold',
                                            fontFamily: 'Inter',
                                            fontSize: '24px',
                                            fontWeight: '600',
                                            lineHeight: '48.41px',
                                            textAlign: 'center',
                                            color: '#FFFFFF'
                                        }}
                                    >
                                        CONNECT AS VIEWER
                                    </Typography>
                                    <Button
                                        sx={{
                                            position: 'absolute',
                                            // top: '50%',
                                            bottom: '10%',
                                            left: '50%',
                                            transform: 'translateX(-50%)',
                                            fontSize: 16,
                                            fontWeight: 500,
                                            fontFamily: 'Inter',
                                            color: 'white',
                                            background:
                                                'linear-gradient(to right, #269D7E 100%, #24CD99 100%)',

                                            borderRadius: '99px',
                                            padding: '10px 20px',
                                            margin: '5px 2px',
                                            border: '1px solid #24CD99',
                                            textTransform: 'none'
                                        }}
                                        onClick={() =>
                                            handleClick('connectAsViewer')
                                        }
                                    >
                                        Connect Now
                                    </Button>
                                </Box>
                            </Grid>

                            <Grid item xs={1}></Grid>

                            <Grid item xs={12} sm={5.5}>
                                <Box
                                    style={{
                                        position: 'relative'
                                    }}
                                >
                                    <img
                                        src={advertiserBackground}
                                        style={{
                                            width: '100%',
                                            objectFit: 'cover',
                                            opacity: '0.5',
                                            height: '15rem',
                                            borderRadius: '14px',
                                            border: '1px solid #505050'
                                        }}
                                        alt=""
                                    />

                                    <Typography
                                        style={{
                                            position: 'absolute',
                                            top: '30%',
                                            left: '30%',
                                            transform: 'translate(-30%, -30%)', // Center text
                                            color: '#FFFFFF',
                                            width: '100%',
                                            fontWeight: 'bold',
                                            fontFamily: 'Inter',
                                            fontSize: '24px',
                                            fontWeight: '600',
                                            lineHeight: '48.41px',
                                            textAlign: 'center',
                                            color: '#FFFFFF'
                                        }}
                                    >
                                        CONNECT AS ADVERTISER
                                    </Typography>
                                    <Button
                                        sx={{
                                            position: 'absolute',
                                            // top: '50%',
                                            bottom: '10%',
                                            left: '50%',
                                            transform: 'translateX(-50%)',
                                            fontSize: 16,
                                            fontWeight: 500,
                                            fontFamily: 'Inter',
                                            color: 'white',
                                            background:
                                                'linear-gradient(to right, #269D7E 100%, #24CD99 100%)',

                                            borderRadius: '99px',
                                            padding: '10px 20px',
                                            margin: '5px 2px',
                                            border: '1px solid #24CD99',
                                            textTransform: 'none'
                                        }}
                                        onClick={() =>
                                            handleClick('connectAsAdvertiser')
                                        }
                                    >
                                        Connect Now
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            spacing={1}
                            sx={{ mt: 5, position: 'relative' }}
                            alignItems="center"
                        >
                            <Grid item xs={12}>
                                <Box
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Button
                                        style={{
                                            color: '#24CD99',
                                            fontFamily: 'Inter',
                                            fontSize: '28px',
                                            fontWeight: '400',
                                            lineHeight: '43.57px',
                                            textAlign: 'center'
                                        }}
                                    >
                                        <img
                                            src={trendLogo}
                                            alt="logo"
                                            style={{
                                                width: '200px'
                                            }}
                                        />
                                    </Button>
                                </Box>
                            </Grid>
                            {/* <Grid item xs={12} sx={{ my: 1 }}>
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography
                                    style={{ color: '#FFFFFF' }}
                                    onClick={() => {
                                        handleClick('Viewers');
                                    }}
                                >
                                    Viewers
                                </Typography>
                            </Box>
                        </Grid> */}
                            {/* <Grid item xs={12} sx={{ my: 1 }}>
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography
                                    style={{ color: '#FFFFFF' }}
                                    onClick={() => {
                                        handleClick('Advertisers');
                                    }}
                                >
                                    Advertisers
                                </Typography>
                            </Box>
                        </Grid> */}
                            {/* <Grid item xs={12} sx={{ my: 1 }}>
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography
                                    style={{
                                        color: '#FFFFFF',
                                        display: 'none'
                                    }}
                                >
                                    Advertising Bundles
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ my: 1 }}>
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography
                                    style={{
                                        color: '#FFFFFF',
                                        display: 'none'
                                    }}
                                >
                                    Docs
                                </Typography>
                            </Box>
                        </Grid> */}
                        </Grid>

                        <Grid container spacint={1} sx={{ mt: 4 }}>
                            <Grid item xs={12}>
                                <Box
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <IconButton
                                        onClick={() => {
                                            window.open(
                                                'https://twitter.com/TTAvatars',
                                                '_blank'
                                            );
                                        }}
                                    >
                                        <img
                                            src={twitterLogo}
                                            alt="twitter"
                                            height="auto"
                                            width="40rem"
                                        />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => {
                                            window.open(
                                                'https://t.me/trendavatars',
                                                '_blank'
                                            );
                                        }}
                                    >
                                        <img
                                            src={telegramLogo}
                                            alt="twitter"
                                            height="auto"
                                            width="40rem"
                                            style={{
                                                marginLeft: '30px'
                                            }}
                                        />
                                    </IconButton>
                                </Box>
                            </Grid>
                            <Grid item xs={12}></Grid>
                        </Grid>

                        <Grid container spacint={1} sx={{ mt: 4 }}>
                            <Grid item xs={12}>
                                <Box
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-around',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Typography
                                        style={{
                                            color: '#24CD99',
                                            fontFamily: 'Inter',
                                            fontSize: '20px',
                                            fontWeight: '500',
                                            lineHeight: '26.04px',
                                            textAlign: 'center'
                                        }}
                                    >
                                        Terms of Services
                                    </Typography>

                                    <Typography
                                        style={{
                                            color: '#24CD99',
                                            fontFamily: 'Inter',
                                            fontSize: '20px',
                                            fontWeight: '500',
                                            lineHeight: '26.04px',
                                            marginLeft: '5rem'
                                        }}
                                    >
                                        Privacy Policy
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sx={{ my: 3 }}>
                                <Typography
                                    style={{
                                        color: '#FFFFFF',
                                        textAlign: 'center'
                                    }}
                                >
                                    &#169; {currentYear} Trend Web3 Ads, All
                                    right Reserved.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </>
            )}
            {snackbarOpen && (
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    open={snackbarOpen}
                    autoHideDuration={3000}
                    onClose={handleCloseSnackbar}
                >
                    <Alert
                        elevation={6}
                        onClose={handleCloseSnackbar}
                        severity={snackbarSeverity}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            )}
        </>
    );
};

export default LandingPage;

const btnStyleGreenWhite = {
    fontSize: 20,
    fontWeight: 500,
    fontFamily: 'Inter',
    color: 'white',
    background: 'linear-gradient(to right, #269D7E 100%, #24CD99 100%)',

    borderRadius: '10px',
    padding: '8px 30px',
    margin: '15px auto',
    border: '1px solid #24CD99',
    textTransform: 'none'
};
